<template>
  <div class="app-container">
    <el-row>
      <el-col :span="2">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="openModalBox"
          >{{ $t("iot.project.add") }}</el-button
        >
      </el-col>
      <el-col :span="6" :offset="16">
        <div class="filter-container" align="right">
          <el-input
            :placeholder="$t('commons.searchPhrase')"
            maxlength="50"
            clearable
            v-model="listQuery.searchPhrase"
            size="small"
            style="width: 200px"
            class="filter-item"
            @keyup.enter.native="searchCondition"
          ></el-input>
          <el-button
            size="small"
            class="filter-item"
            type="primary"
            :loading="listLoading"
            icon="el-icon-search"
            @click="searchCondition"
          >
            {{ $t("commons.search") }}
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        stripe
        highlight-current-row
      >
        <el-table-column
          :label="$t('iot.project.name')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.projectName }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('iot.project.code')" width="120">
          <template slot-scope="s">
            <span>{{ s.row.projectCode }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('organization.company.name')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.companyName }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('iot.project.city')" width="120">
          <template slot-scope="s">
            <span>{{ s.row.cityName }}</span>
            <a v-if="s.row.location" @click="openMapLocation(s.row)">
              <i class="el-icon-map-location"></i>
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('iot.project.address')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="s">
            <span>{{ s.row.address }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('iot.project.floorSpace')"
          width="120"
          align="right"
        >
          <template slot-scope="s">
            <span>{{ s.row.floorSpace }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="100"
          :label="$t('commons.actions')"
        >
          <template slot-scope="s">
            <el-tooltip :content="$t('commons.edit')">
              <el-button
                @click="editProject(s.row.projectId)"
                circle
                type="success"
                icon="el-icon-edit"
                size="mini"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip :content="$t('commons.delete')">
              <el-button
                @click="deleteProjectList(s.row.projectId, s.row.projectName)"
                circle
                type="danger"
                icon="el-icon-delete"
                size="mini"
              >
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.current"
        :limit.sync="listQuery.rowCount"
        @pagination="getProjectListsPage"
      />
    </el-row>

    <el-dialog
      :close-on-click-modal="false"
      width="40%"
      top="6vh"
      :visible.sync="addModalBox"
      :before-close="cancelProjectListSave"
    >
      <template slot="title"> <title-icon />{{ title }} </template>
      <el-form
        label-position="top"
        ref="projectForm"
        :rules="projectFormRules"
        :model="form"
      >
        <el-row>
          <el-col :span="11">
            <el-form-item :label="$t('registry.name')" prop="projectName">
              <el-input
                v-model="form.projectName"
                maxlength="20"
                :show-word-limit="true"
                @input="setCode()"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item :label="$t('iot.project.code')" prop="projectCode">
              <el-input
                v-model="form.projectCode"
                maxlength="16"
                :show-word-limit="true"
                :readonly="readProjectCode"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              :label="$t('organization.company.name')"
              prop="companyId"
            >
              <el-select v-model="form.companyId" :style="{ width: '100%' }">
                <el-option
                  v-for="item in companyList"
                  :label="item.companyName"
                  :value="item.companyId"
                  :key="item.companyId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item :label="$t('iot.project.city')" prop="cityName">
              <el-autocomplete
                style="width: 100%"
                class="inline-input"
                v-model="form.cityName"
                clearable
                @clear="clearCity()"
                @select="handleSelect"
                :fetch-suggestions="querySearch"
                :placeholder="$t('commons.pleaseInput')"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('iot.project.address')">
              <el-input
                v-model="form.address"
                maxlength="80"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              :label="$t('iot.project.floorSpace')"
              prop="floorSpace"
            >
              <el-input
                v-model="form.floorSpace"
                maxlength="11"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item :label="$t('iot.project.location')">
              <el-input
                :readonly="true"
                v-model="form.location"
                class="input-with-select"
                :placeholder="$t('commons.selectPlease')"
              >
                <el-button
                  slot="append"
                  @click="openMapSelect"
                  icon="el-icon-location"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('iot.project.label')">
              <tag-bind :tagValueList="form.tagList" tagType="project" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('iot.project.desc')">
              <el-input
                v-model="form.description"
                maxlength="160"
                :show-word-limit="true"
                type="textarea"
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelProjectListSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="saveNewProjectList">{{
          $t("commons.save")
        }}</el-button>
      </div>
      <el-input type="hidden" class="hidden" v-model="hiddenData"></el-input>
    </el-dialog>

    <map-select
      :position="position"
      :cityName="form.cityName"
      :actions="isAction"
      :mapSelectDialog="mapSelectDialog"
    />
  </div>
</template>

<script>
import {
  getProjectListPage,
  updateProjectList,
  deleteProjectList,
  insertProject,
  getOwnProject,
} from "@/api/ruge/iot/project/project";
import { getCompanyList } from "@/api/ruge/organization/tenant/employee";
import Pagination from "@/components/Pagination";
import TagBind from "@/components/TagBind";
import pinyin from "js-pinyin";
import { validateNumber } from "@/utils/validate";
import cityData from "./city.json";
import MapSelect from "@/components/MapSelect";

export default {
  name: "projectManager",
  components: { Pagination, TagBind, MapSelect },
  data() {
    return {
      position: null,
      mapSelectDialog: false,
      isAction: true,
      isActive: true,
      readProjectCode: false,
      total: 0,
      list: null,
      listLoading: false,
      companyList: [],
      hiddenData: "",
      listQuery: {
        //分页参数
        current: 1,
        rowCount: 10,
        searchPhrase: null,
      },
      form: {
        projectId: null,
        projectName: null,
        projectCode: null,
        companyId: null,
        cityName: null,
        address: null,
        floorSpace: null,
        location: null,
        description: null,
        tagList: [],
      },
      projectFormRules: {
        projectName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        projectCode: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        companyId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        cityName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        floorSpace: [
          {
            required: false,
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (value && !validateNumber(value)) {
                callback(new Error(this.$t("iot.monitor.validMessage.number")));
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      //默认设置模态框不可见
      addModalBox: false,
      title: null,
    };
  },
  created() {
    this.searchCondition();
    this.getCompanyList();
    this.citys = cityData;
  },
  methods: {
    selectMapPosition(position) {
      this.form.location = position.lng + "," + position.lat;
      this.mapSelectDialog = false;
    },

    openMapSelect() {
      if (this.form.cityName) {
        //设置为可操作
        this.isAction = true;
        if (this.form.location != null) {
          this.position = {
            lng: parseFloat(this.form.location.split(",")[0]),
            lat: parseFloat(this.form.location.split(",")[1]),
          };
        } else {
          this.position = null;
        }
        this.mapSelectDialog = true;
      } else {
        this.$message({
          message: this.$t("iot.project.pleaseSelectCity"),
          type: "info",
        });
      }
    },

    openMapLocation(row) {
      if (row.location) {
        //设置为不可操作
        this.isAction = false;
        this.position = {
          lng: parseFloat(row.location.split(",")[0]),
          lat: parseFloat(row.location.split(",")[1]),
        };
        this.mapSelectDialog = true;
      } else {
        this.$message({
          message: this.$t("iot.project.locationNotExist"),
          type: "info",
        });
      }
    },

    clearCity() {
      this.form.cityName = null;
      this.form.location = null;
    },
    handleSelect(item) {
      this.form.cityName = item.value;
      this.form.location = null;
    },

    querySearch(queryString, cb) {
      let citys = this.citys;
      let results = queryString
        ? citys.filter(this.createFilter(queryString))
        : citys;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (city) => {
        return (
          city.label.toLowerCase().indexOf(queryString.toLowerCase()) != -1
        );
      };
    },

    setCode() {
      // 编辑项目时，编码不可修改
      if (!this.readProjectCode) {
        let name = this.form.projectName;
        this.form.projectCode = pinyin.getCamelChars(name);
      }
    },
    //清除条件搜索输入框
    clearSearchInput() {
      this.listQuery.projectName = "";
    },
    //条件搜索
    searchCondition() {
      console.log("searchCondition", this.listQuery);
      this.listQuery.current = 1;
      this.getProjectListsPage();
    },
    //开启模态框
    openModalBox() {
      this.title = this.$t("iot.project.add");
      this.clearForm();
      this.readProjectCode = false;
      this.hiddenData = "";
      this.addModalBox = true;
    },
    //关闭模态框
    cancelProjectListSave() {
      this.$refs.projectForm.resetFields();
      this.addModalBox = false;
    },
    //查询公司列表
    getCompanyList() {
      getCompanyList().then((res) => {
        this.companyList = res;
      });
    },
    //加载table
    getProjectListsPage() {
      this.listLoading = true;
      getProjectListPage(this.listQuery)
        .then((res) => {
          this.list = res.rows.map((v) => {
            return v;
          });
          this.total = res.total;
          this.listLoading = false;
        })
        .catch((error) => {
          this.listLoading = false;
          console.log(`查询失败，原因=> ${error}`);
        });
    },
    //修改
    editProject(projectId) {
      this.title = this.$t("iot.project.edit");
      this.readProjectCode = true;
      this.clearForm();
      this.hiddenData = projectId;
      getOwnProject(projectId).then((res) => {
        this.addModalBox = true;
        this.form.projectName = res.projectName;
        this.form.projectCode = res.projectCode;
        this.form.companyId = res.companyId;
        this.form.cityName = res.cityName;
        this.form.address = res.address;
        this.form.floorSpace = res.floorSpace;
        this.form.location = res.location;
        this.form.description = res.description;
        this.form.tagList = res.tagList;
      });
    },
    //删除
    deleteProjectList(projectId, projectName) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteProjectList({ projectId: projectId, projectName: projectName })
            .then(() => {
              //成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              //删除成功后重新渲染table
              this.searchCondition();
            })
            .catch((error) => {
              console.log(`删除失败，原因 => ${error}`);
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    //保存
    saveNewProjectList() {
      this.$refs.projectForm.validate((v) => {
        if (v) {
          //去重复
          this.form.tagList = Array.from(new Set(this.form.tagList));
          if (this.hiddenData) {
            this.form.projectId = this.hiddenData;
            updateProjectList(this.form).then(() => {
              //关闭模态框
              this.addModalBox = false;
              //修改成功的提示
              this.$message({
                message: this.$t("message.saveSuccess"),
                type: "success",
              });
              //重新加载table
              this.getProjectListsPage();
            });
          } else {
            insertProject(this.form).then(() => {
              //关闭模态框
              this.addModalBox = false;
              //保存成功的提示
              this.$message({
                message: this.$t("message.saveSuccess"),
                type: "success",
              });
              //重新加载table
              this.getProjectListsPage();
            });
          }
        }
      });
    },
    //清空
    clearForm() {
      this.form.projectId = null;
      this.form.projectName = null;
      this.form.projectCode = null;
      this.form.companyId = null;
      this.form.cityName = null;
      this.form.address = null;
      this.form.floorSpace = null;
      this.form.location = null;
      this.form.description = null;
      this.form.tagList = [];
    },
  },
};
</script>
