var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "small",
                  },
                  on: { click: _vm.openModalBox },
                },
                [_vm._v(_vm._s(_vm.$t("iot.project.add")))]
              ),
            ],
            1
          ),
          _c("el-col", { attrs: { span: 6, offset: 16 } }, [
            _c(
              "div",
              { staticClass: "filter-container", attrs: { align: "right" } },
              [
                _c("el-input", {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: {
                    placeholder: _vm.$t("commons.searchPhrase"),
                    maxlength: "50",
                    clearable: "",
                    size: "small",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.searchCondition.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.listQuery.searchPhrase,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "searchPhrase", $$v)
                    },
                    expression: "listQuery.searchPhrase",
                  },
                }),
                _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    attrs: {
                      size: "small",
                      type: "primary",
                      loading: _vm.listLoading,
                      icon: "el-icon-search",
                    },
                    on: { click: _vm.searchCondition },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("commons.search")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                stripe: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("iot.project.name"),
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (s) {
                      return [_c("span", [_vm._v(_vm._s(s.row.projectName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("iot.project.code"), width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (s) {
                      return [_c("span", [_vm._v(_vm._s(s.row.projectCode))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("organization.company.name"),
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (s) {
                      return [_c("span", [_vm._v(_vm._s(s.row.companyName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("iot.project.city"), width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (s) {
                      return [
                        _c("span", [_vm._v(_vm._s(s.row.cityName))]),
                        s.row.location
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.openMapLocation(s.row)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-map-location" })]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("iot.project.address"),
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (s) {
                      return [_c("span", [_vm._v(_vm._s(s.row.address))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("iot.project.floorSpace"),
                  width: "120",
                  align: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (s) {
                      return [_c("span", [_vm._v(_vm._s(s.row.floorSpace))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  label: _vm.$t("commons.actions"),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (s) {
                      return [
                        _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("commons.edit") } },
                          [
                            _c("el-button", {
                              attrs: {
                                circle: "",
                                type: "success",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editProject(s.row.projectId)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("commons.delete") } },
                          [
                            _c("el-button", {
                              attrs: {
                                circle: "",
                                type: "danger",
                                icon: "el-icon-delete",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteProjectList(
                                    s.row.projectId,
                                    s.row.projectName
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.current,
              limit: _vm.listQuery.rowCount,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "rowCount", $event)
              },
              pagination: _vm.getProjectListsPage,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            width: "40%",
            top: "6vh",
            visible: _vm.addModalBox,
            "before-close": _vm.cancelProjectListSave,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addModalBox = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [_c("title-icon"), _vm._v(_vm._s(_vm.title) + " ")],
            1
          ),
          _c(
            "el-form",
            {
              ref: "projectForm",
              attrs: {
                "label-position": "top",
                rules: _vm.projectFormRules,
                model: _vm.form,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("registry.name"),
                            prop: "projectName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "20",
                              "show-word-limit": true,
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setCode()
                              },
                            },
                            model: {
                              value: _vm.form.projectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "projectName", $$v)
                              },
                              expression: "form.projectName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("iot.project.code"),
                            prop: "projectCode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "16",
                              "show-word-limit": true,
                              readonly: _vm.readProjectCode,
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.projectCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "projectCode", $$v)
                              },
                              expression: "form.projectCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("organization.company.name"),
                            prop: "companyId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              model: {
                                value: _vm.form.companyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "companyId", $$v)
                                },
                                expression: "form.companyId",
                              },
                            },
                            _vm._l(_vm.companyList, function (item) {
                              return _c("el-option", {
                                key: item.companyId,
                                attrs: {
                                  label: item.companyName,
                                  value: item.companyId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("iot.project.city"),
                            prop: "cityName",
                          },
                        },
                        [
                          _c("el-autocomplete", {
                            staticClass: "inline-input",
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              "fetch-suggestions": _vm.querySearch,
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            on: {
                              clear: function ($event) {
                                return _vm.clearCity()
                              },
                              select: _vm.handleSelect,
                            },
                            model: {
                              value: _vm.form.cityName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cityName", $$v)
                              },
                              expression: "form.cityName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("iot.project.address") } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "80",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("iot.project.floorSpace"),
                            prop: "floorSpace",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "11",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.form.floorSpace,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "floorSpace", $$v)
                              },
                              expression: "form.floorSpace",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("iot.project.location") } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: {
                                readonly: true,
                                placeholder: _vm.$t("commons.selectPlease"),
                              },
                              model: {
                                value: _vm.form.location,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "location", $$v)
                                },
                                expression: "form.location",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-location",
                                },
                                on: { click: _vm.openMapSelect },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("iot.project.label") } },
                        [
                          _c("tag-bind", {
                            attrs: {
                              tagValueList: _vm.form.tagList,
                              tagType: "project",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("iot.project.desc") } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "160",
                              "show-word-limit": true,
                              type: "textarea",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelProjectListSave } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveNewProjectList },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
          _c("el-input", {
            staticClass: "hidden",
            attrs: { type: "hidden" },
            model: {
              value: _vm.hiddenData,
              callback: function ($$v) {
                _vm.hiddenData = $$v
              },
              expression: "hiddenData",
            },
          }),
        ],
        2
      ),
      _c("map-select", {
        attrs: {
          position: _vm.position,
          cityName: _vm.form.cityName,
          actions: _vm.isAction,
          mapSelectDialog: _vm.mapSelectDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }